import './index.scss'
import Swiper from 'swiper/swiper-bundle.min.js'

// 首页banner轮播
var interleaveOffset = 0.5; //视差比值
var swiperOptions = {
    loop: true,
    speed: 1000,
    grabCursor: true,
    watchSlidesProgress: true,
    mousewheelControl: true,
    keyboardControl: true,
    // autoplay: {
    //     delay: 5000,
    //     stopOnLastSlide: false,
    //     disableOnInteraction: false,
    // },
    navigation: {
        nextEl: "#banner-swiper .swiper-button-next",
        prevEl: "#banner-swiper .swiper-button-prev",
    },
    pagination: {
        el: ".swiper-pagination",
    },
    // on: {
    //     progress: function (swiper) {
    //         for (var i = 0; i < swiper.slides.length; i++) {
    //             var slideProgress = swiper.slides[i].progress;
    //             var innerOffset = swiper.width * interleaveOffset;
    //             var innerTranslate = slideProgress * innerOffset;
    //             swiper.slides[i].querySelector("#banner-swiper .swiper-slide img").style.transform =
    //                 "translate3d(" + innerTranslate + "px, 0, 0)";
    //         }
    //     },
    //     touchStart: function (swiper) {
    //         for (var i = 0; i < swiper.slides.length; i++) {
    //             swiper.slides[i].style.transition = "";
    //         }
    //     },
    //     setTransition: function (swiper, speed) {
    //         for (var i = 0; i < swiper.slides.length; i++) {
    //             swiper.slides[i].style.transition = speed + "ms";
    //             swiper.slides[i].querySelector("#banner-swiper .swiper-slide img").style.transition =
    //                 speed + "ms";
    //         }
    //     },
    // },
    
};
var swiper = new Swiper("#banner-swiper", swiperOptions);
let bannerNext = $("#banner-swiper .swiper-button-next") 
let bannerPrev = $("#banner-swiper .swiper-button-prev") 
bannerNext.click(function () {
        bannerNext.addClass("active")
        bannerPrev.removeClass("active")          
})
bannerPrev.click(function () {
    bannerPrev.addClass("active")
    bannerNext.removeClass("active")    
})

var swiper = new Swiper('#ph-swiper', {
    speed: 1000,
    grabCursor: true,
    watchSlidesProgress: true,
    mousewheelControl: true,
    keyboardControl: true,
    loop:true,
    // autoplay: {
    //     delay: 5000,
    //     stopOnLastSlide: false,
    //     disableOnInteraction: false,
    // },
    paginationType: 'fraction',
});